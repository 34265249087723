// .container {
//   box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
//   border-radius: 10px;
// }
@import "../../components/variables/variables.scss";

.container {
  width: 97%;

  .header {
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
    color: $lightRed;
    font-weight: 900;
  }

  .formInput {
    box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
    border-radius: 10px;
    /* height: 45px; */
    /* font-size: 13px; */
  }

  .requestHeader,
  .requestHeader1 {
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: $lightRed;
    text-transform: uppercase;
    margin-top: 20px;
  }
  .requestHeader {
    text-decoration: underline;
  }
  .request,
  .request:hover,
  .request:focus {
    display: flex;
    margin: 20px auto;
    padding: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $darkRed;
    color: $white;
    gap: 20px;
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    box-shadow: 1px 2px 10px 3px #ffd0d0;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
  }
}

.allProduct {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.top {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

.title {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.eachProduct {
  min-width: 45%;
  // border: 1px solid red;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  border-radius: 10px;
}

.eachProduct .each {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .line {
    background-color: rgb(230, 223, 223);
    height: 1px;
    width: 60%;
  }
}

.icon {
  background: #da621b;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delivery-type-container {
  border: 1px solid #da621b;
  margin: 10px;
  padding: 10px;
}

label {
  color: black;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  border-radius: 100%;
}

.container .input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
  vertical-align: middle;
}

.container:hover .input ~ .checkmark {
  background-color: #ccc;
}

.container .input:checked ~ .checkmark {
  background-color: #2196f3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container .input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.next {
  background-color: #da621b;
  color: white;
  width: 90%;
  margin: 20px;
  font-weight: 600;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.control {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  .controlBtn {
    cursor: pointer;
    padding: 0px 10px;
    box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  }
  .qty {
    background-color: rgb(233, 229, 229);
    padding: 3px 10px;
    border-radius: 5px;
    font-size: 17px;
  }
}

.price {
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  padding: 3px 10px;
  font-weight: 600;
  border-radius: 5px;
  font-size: 16px;
}

.instructionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .total {
    background-color: rgb(235, 230, 230);
    border-radius: 20px;
    margin-bottom: 20px;
    margin-top: 30px;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 19px;

    .naira {
      font-weight: bold;
    }
  }

  .warning {
    font-size: 18px;
    margin-bottom: 30px;
    color: $textColor;
    text-align: center;
    .naira {
      font-weight: bold;
    }
  }

  .okay {
    background-color: #da621b;
    color: white;
    width: 90%;
    margin: 20px;
    font-weight: 600;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.desc {
  font-size: 16px;
}
