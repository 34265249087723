.title {
  font-size: 25px;
  font-weight: 700;
  color: #950303;
  text-transform: uppercase;
  margin-top: 2rem;
}

.button {
  justify-content: right;
  background-color: #b70303;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
