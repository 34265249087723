.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 97%;
}

.link {
  text-decoration: none;
}
