@import "../../components/variables/variables.scss";
.header {
  font-size: 30px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  color: #b70303;
}

.chatContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 300px;
  overflow-y: auto;
}

.messageContainer {
  display: flex;
}
.rp {
  display: flex;
  gap: 5px;
  margin-left: auto;

  .imgP{
    display: flex;
    position: relative;
  }

  .replyText {
    background-color: #b70303;
    color: white;
    padding: 10px;
    border-radius: 8px;
    margin-left: auto;
  }
  .img {
    background: #b70303;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
  }

  p{
    color: white;
    font-weight: bold;
    position: absolute;
    padding: 8px;
  }
}

.rc {
  display: flex;
  gap: 5px;

  .imgP{
    display: flex;
    position: relative;
  }

  .receivedText {
    background-color: #7b7575;
    color: white;
    padding: 8px;
    border-radius: 8px;
  }

  .img2 {
    background: #7b7575;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
  }

  p{
    color: white;
    font-weight: bold;
    position: absolute;
    padding: 8px;
  }
}

.inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  position: relative;

  textarea {
    width: 100%;
    height: 4rem;
    outline: none;
    border-radius: 10px;
    align-items: center;
    padding: 8px;
    // overflow: hidden;
    border-color: #7b7575;
    color: #7b7575;
  }

  .icon {
    position: absolute;
    cursor: pointer;
    right: 1rem;
    color: #b70303;
    font-size: larger;
  }
}

.tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #333;
  color: #fff;
  padding: 5px;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 30%;
  margin-left: -80px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 15px;
}

.icon:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.modal {
  .close {
    z-index: 13000000;
    position: absolute;
    top: -13px;
    left: -13px;
    background-color: #7b7575;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    color: $white;
  }

  @media screen and (max-width: 600px) {
    .container {
      overflow-x: scroll;
      padding: 10px 20px;
    }
  }
}
