@import "../../components/variables/variables.scss";

.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 30%;
}

@media (min-width: 968px) {
  .form {
    padding: 0 5rem;
  }
}

.heading {
  color: #27156f;
  font-weight: 400;
}

.inputText {
  font-size: 12px;
}

.button {
  width: 100%;
  border-radius: 5px;
  font-weight: 600;
  background-color: $darkRed;
  border: 1px solid $darkRed;
  color: $white;

  &:hover,
  &:focus {
    background: $lightRed;
    border: 1px solid $lightRed;
    color: $white;
  }
}

.input {
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  background-color: #f4f4f4;
  border: none;
}

.group {
  position: relative;
}

.icon {
  position: absolute;
  top: 8px;
  right: 20px;
  color: #555555;
  font-size: 1.2rem;
}

.icon2 {
  position: absolute;
  top: 3px;
  right: 20px;
  color: #555555;
  font-size: 1.2rem;
}
