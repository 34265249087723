@import '../variables/variables.scss';
.modal {
  position: relative;
  .close {
    z-index: 1;
    position: absolute;
    top: -33px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 45%;
    cursor: pointer;
    // background-color: #484545;
    // border-radius: 50%;
    color: $white;
  }

  .actionBtn {
    font-size: 13px;
    // min-width: 190px;
    border: none;
    border-radius: 5px;
    width: 22px;
    padding: 9px;
    margin: 0 0.5rem;
    font-weight: 700;
  }

  .confirm,
  .confirm:hover,
  .confirm:focus {
    background: #484545;
    color: $white;
  }

  .reject,
  .reject:hover,
  .reject:focus {
    background: $darkRed;
    color: $white;
  }
}
