/* modalStyles.css */
.modal-dialog {
  max-width: 90%;
}

.modal-content {
  display: flex;
  justify-content: center;
  width: 50%;
  height: 80vh;
  margin: 0 auto;
  background-color: #b70303;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.modal-title {
  margin: 0 auto;
  font-weight: 600;
}

.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 80vh;
  margin: 0 auto;
  font-size: 36px;
  line-height: 45px;
  color: #fff;
}

.modal-backdrop {
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
}
