@import "../../../components/variables/variables.scss";

.container {
  width: 97%;

  .header {
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
    color: $lightRed;
    font-weight: 900;
  }
  .contents {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  .cards {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;

    &_card {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 40%;
      max-width: 40%;
      min-height: 40vh;
      max-height: 40vh;
      border-radius: 10px;
      background-color: $lightRed;
      color: $white;
      cursor: pointer;
      text-transform: uppercase;
      text-align: center;
      font-weight: 600;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      font-size: 2.5rem;
    }
  }
}
