@import "../variables/variables.scss";

.container {
  display: flex;
  justify-content: end;
  margin-right: 30px;
  margin-bottom: 10px;
}

.paginationBtn {
  width: 80%;
  height: 30px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  color: $lightRed;
  margin-top: 10px;
}

/* .paginationBtn:hover{
    color: white;
  } */

.paginationBtn a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 10px;
  width: fit-content;
  font-size: 14px;
  margin: 3px;
  border-radius: 50%;
  // border: 1px solid $darkRed;
  color: $darkRed;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $lightRed;
  }
}

.paginationActive {
  color: $darkRed;
}
.paginationActive a {
  color: $darkRed;
  background-color: #fde0e0;
}

.paginationDisabled {
  display: none;
}
