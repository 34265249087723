.tabContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

// .orderContainer {
//   overflow: scroll;
// }
.tab {
  display: flex;
  justify-content: space-between;
}

.btn {
  cursor: pointer;
  color: #8f8f8f;
}

.btnActive {
  border-bottom: 2px solid black;
}

.tab2 {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.btn2 {
  cursor: pointer;
  color: #707174;
  // z-index: 20000;
  display: flex;
  position: relative;
}

.btnActive2 {
  border-bottom: 2px solid #950303;
  color: #950303;
  display: flex;
  position: relative;
}
.count {
  background-color: #950303;
  color: #fff;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  position: absolute;
  top: -8px;
  right: -40px;
}

.line {
  width: 1px;
  height: 30px;
  background-color: #c3c4cb;
}

.container {
  margin: 20px;
  padding: 20px;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
}

.status {
  background-color: #ff0000;
  border: 2px solid transparent;
  border-radius: 7px;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0px 5px;
  font-size: 12px;
}

.status2 {
  background-color: #faac8a;
  border: 2px solid transparent;
  border-radius: 7px;
  color: #a71616;
  display: flex;
  align-items: center;
  padding: 0px 5px;
  font-size: 12px;
}

.delay {
  color: #ff0000;
  font-weight: 800;
}

.header {
  color: #8c94b2;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}

.content1 {
  color: #0d1533;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  margin-bottom: 5px;
}

.content,
.content2 {
  color: #0d1533;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}

.content2 {
  color: #8c94b2;
}

.link {
  text-decoration: none;
  color: #8c94b2;
}

.link:hover {
  color: #0d1533;
}

.scroll {
  max-height: 75vh;
  overflow-y: scroll;
}
