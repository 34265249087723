.title {
  padding-left: 16px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #061938;
}
.formContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;

  label {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #7a869a;
  }

  .formRow {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  select {
    width: 20rem;
  }

  .formGroup label {
    margin-bottom: 5px;
    font-weight: bold;
  }

  .formGroup label span {
    color: red;
  }

  .formGroup input,
  .formGroup select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }

  .formGroup input:focus,
  .formGroup select:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
  }
}
