.header {
  color: rgb(141, 33, 33);
  width: 100%;
  /* text-align: center; */
  font-size: 26px;
  padding: 10px;
  margin-bottom: 20px;
}
.cardText {
  text-align: center;
  font-size: 20px;
}
.container {
  box-shadow: 2px 2px 4px gray;
  border-radius: 4px;
  /* display: flex; */
  flex-direction: row;
  margin: 0px auto;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f7f7f7;
  width: 100%;
  padding: 20px;
  margin-top: 20px;
}

.card {
  /* display: flex;
  flex-direction: row;
  width: 100%; */
  padding: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.carditem {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.carditem:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.carditem:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.headerrow {
  width: 100%;
  padding: 10px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.inputField {
  /* width: 95%; */
  height: 38px;
  border: none;
  outline: none;
  font-size: 16px;
  color: black;
  padding: 10px;
}
.button {
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  background-color: rgb(141, 33, 33);
  width: 80%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  align-items: center;
}
.buttonclick:hover {
  cursor: pointer;
  background-color: white;
  border: 2px rgb(141, 33, 33) solid;
}
.buttonTextdisable:hover {
  transition: all 0.3s ease-in-out;
  color: rgb(141, 33, 33);
}

.buttonText {
  align-self: center;
  font-weight: bold;
  font-size: 20px;
  color: white;
  text-align: center;
  width: 100%;
  padding: 10px;
}

.centeredButtonContainer {
  width: 40%;
  display: flex;
  justify-content: center;
  margin: 0px auto;
  align-items: center;
  /* margin-top: 20px;
  margin-bottom: 20px; */
}

.table {
  width: 95%;
  margin: 0px auto;
  padding: 15px;
}
.headeritem {
  font-weight: bold;
  font-size: 20px;
}
.disabledButton {
  background-color: #ccc;
  color: #888;
  cursor: not-allowed;
}
.buttonclickable {
}
