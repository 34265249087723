@import "../../variables/variables.scss";

.container {
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: 20px auto;

  .tube {
    background: #f8f8f8;
    border-radius: 5px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .question,
  .text {
    color: #85878e;
    font-size: 18px;
    font-weight: 900;
  }

  .text {
    color: #323c47;
    margin-bottom: 10px;
  }

  .text2 {
    color: $lightRed;
    margin-bottom: 10px;
    font-size: 16px;
  }

  .line {
    border: 1px solid #ededed;
  }

  .up,
  .down {
    background: $darkRed;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    cursor: pointer;
  }

  .down {
    background-color: #f0f0f0;
    margin-left: 10px;
  }
}

.btn,
.btn:hover,
.btn:focus {
  width: 100%;
  background: $darkRed;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  border-radius: 10px;
  border: inherit;
  margin: 20px auto;
  padding: 10px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 20px;
  color: $white;
}
