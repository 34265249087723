@import "../../components/variables/variables.scss";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 97%;

  .textContainer1 {
    background: $white;
    border-radius: 20px;
    box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  }

  .textContainer {
    background-color: $darkRed;
    color: $white;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 900;
    border-radius: 20px;
  }

  .container2 {
    display: flex;
    justify-content: flex-end;
  }
}

.menu {
  margin-top: 30px;
  width: 97%;
  height: 50%;

  // .column1 {
  //   overflow: scroll;
  //   max-height: 90vh;
  // }
}

@media screen and (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .menu {
    max-width: 97%;
    display: flex;
    flex-direction: column;

    .column1 {
      display: none;
    }
  }
}
