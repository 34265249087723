@import "../variables/variables.scss";

.informationContainer {
  min-width: 250px;
  display: flex;
  flex-direction: column;
  margin: 15px auto;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  border-radius: 10px;

  .percent {
    display: flex;
    justify-content: flex-end;
    margin: 5px 10px;
  }

  .label {
    font-size: 20px;
    font-weight: 900;
    color: #4a4242;
    text-align: center;
  }

  .contents {
    flex: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 1.2rem;
      font-weight: 900;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      color: $darktext;
    }

    .value {
      font-size: 2rem;
      font-weight: 700;
      display: flex;
      justify-content: center;
    }

    .text {
      font-size: 1.5rem;
      font-weight: 900;
    }

    .pint {
      font-weight: 900;
      font-size: 24px;
      color: #323c47;
    }
  }
}

p {
  margin-bottom: 0rem;
}

.Container {
  /* height: 15vh; */
  width: 75%;
  display: flex;
  height: 223px;
  margin: 15px auto;
  cursor: pointer;
  &:hover,
  &:focus {
    box-shadow: 1px 2px 10px 10px rgb(202, 193, 193);
    border-radius: 10px;
  }

  .content {
    flex: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
    border-radius: 10px;
  }

  .name {
    font-weight: 700;
    font-size: 30px;
  }
}

.container {
  /* height: 15vh; */
  width: 220px;
  display: flex;
  flex-direction: column;
  margin: 15px auto;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  border-radius: 5px;
  padding: 20px 20px;

  .header {
    color: #323c47;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .icon {
    display: flex;
    // align-items: center;
    color: #707174;
    font-size: 14px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 768px) {
  .informationContainer {
    flex-direction: column;
    /* height: 20vh; */
  }
}

@media (max-width: 425px) {
  .title {
    font-size: 12px;
  }
}
