@import "../../variables/variables.scss";

.container {
  border-radius: 15px;
  padding: 5px 10px;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  margin: 20px 10px;

  .bott {
    display: flex;
    align-items: center;
    // justify-content: space-between;

    .button2 {
      border: 1px solid #b73035;
      color: #b73035;
      font-size: 11px;
      text-transform: uppercase;
      background-color: #fff;
      padding: 2px 21px;
    }

    .time {
      color: #85878e;
      font-size: 12px;
      margin-right: 8px;
    }
    .update {
      color: #b70303;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .innerContainer {
    display: flex;
    justify-content: space-between;
  }

  .content {
    display: flex;
    text-overflow: ellipsis;
    cursor: pointer;

    .icon {
      border-radius: 50%;
      background-color: #fde0e0;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }

    .header {
      font-size: 12px;
      color: #707174;
      text-transform: uppercase;
      margin: 0%;
      margin-bottom: 5px;
    }

    .text {
      color: #07153d;
      font-size: 14px;
      width: 150px;
    }

    .button {
      border: 1px solid #b73035;
      color: #b73035;
      font-size: 11px;
      text-transform: uppercase;
      background-color: #fff;
      padding: 2px 15px;
      margin-bottom: 10px;
    }
  }

  .address {
    width: 200px;
  }
}

.header2 {
  text-align: center;
  text-transform: uppercase;
  color: $lightRed;
  margin-bottom: 10px;
  font-weight: 700;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 1rem auto;
}

@media screen and (max-width: 600px) {
  .container {
    overflow-x: scroll;
    padding: 10px 20px;
  }
}
