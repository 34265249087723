@import "../variables/variables.scss";

.actionBtn {
  font-size: 13px;
  min-width: 190px;
  border: none;
  border-radius: 5px;
  width: 22px;
  padding: 9px;
  margin: 0 0.5rem;
  font-weight: 700;
  text-align: center;
}

.confirm,
.confirm:hover,
.confirm:focus {
  background: #484545;
  color: $white;
}

.reject,
.reject:hover,
.reject:focus {
  background: $darkRed;
  color: $white;
}
