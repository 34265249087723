@import "../../components/variables/variables.scss";

.adminDashboardCont {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fafafa;

  .sidebarContOuter {
    // width: 25%;
    height: 100%;
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    pointer-events: fill;
  }

  .mainContent {
    width: 93%;
    margin-left: 6%;
    padding-left: 20px;
    background-color: $white;
  }

  .mainDashboardContent {
    background-color: #fafafa;
    height: 100vh;
  }
}

@media (max-width: 1200px) {
  .sidebarMobileShadow {
    position: fixed;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    pointer-events: none;
  }
}

/* Responsiveness */
@media (max-width: 1223px) {
  .mainContent {
    width: 100%;
    margin-left: 0;
    z-index: 1;
  }
}

@media (max-width: 1024px) {
  .sidebarContOuter {
    width: 30%;
  }
}

@media (max-width: 425px) {
  .sidebarContOuter {
    width: 60%;
    // display: none;
  }
}
