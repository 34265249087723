@import '../../variables/variables.scss';

.modalContainer {
  display: flex;
  /* gap: 40px; */
  background: rgba(255, 249, 249, 0.92);
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.25);
  border-radius: 10px;
  margin-bottom: 10px;
}

.head {
  font-weight: 900;
  font-size: 14px;
  width: 15vw;
  text-transform: uppercase;
  margin: 15px;
}
.line {
  background-color: #f2f2f2;
  width: 4px;
}
.head2 {
  font-weight: 900;
  font-size: 14px;
  margin: 15px;
  color: #4374a6;
  font-style: italic;
}

.modalContainer1 {
  display: flex;
  background: rgba(255, 255, 255, 0.92);
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.25);
  border-radius: 10px;
  margin-bottom: 10px;
}

.title {
  font-weight: 900;
  font-size: 14px;
  text-transform: uppercase;
  margin: 15px;
  color: #707174;
  width: 15vw;
}

.content {
  color: #707174;
  font-weight: 900;
  font-size: 14px;
  margin: 15px;
  font-style: italic;
}

.breadcumb {
  color: $lightRed;
  font-size: 18px;
  font-weight: 700;
}

.header {
  color: #4374a6;
  font-weight: 800;
  font-size: 22px;
}

.id {
  background-color: $green;
  color: $white;
  font-size: 13px;
  padding: 5px 15px;
}

.select {
  width: 100%;
  border: none;
}

.return {
  color: $white;
  font-size: 28px;
  font-weight: 800;
  margin-top: 10px;
}

.note {
  color: #f2f2f2;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  width: 400px;
  text-align: center;
  margin-top: 10px;
}

.line2 {
  background-color: #b44343;
  margin: 10px 0px;
  height: 2px;
  width: 100%;
}

.btn2 {
  border: none;
  background-color: none;
  cursor: pointer;
  color: $white;
  font-size: 24px;
  font-weight: 600;
}
