@import "../../../components/variables/variables.scss";

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10rem;

  .outerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .iconContainer {
      background-color: #bcdefe;
      border-radius: 10px;
      padding: 20px;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 3rem;
      text-transform: uppercase;

      .size {
        background-color: rgb(242, 242, 242);
        font-size: 13px;
        padding: 10px 20px;
        border-radius: 20px;
        color: $darktext;
      }
    }

    .question {
      color: $textColor;
      font-size: 14px;
      margin-top: 0.7rem;
      margin-bottom: 3rem;
    }

    .button {
      width: 100%;
      border-radius: 5px;
      font-weight: 600;
      margin-bottom: 0.7rem;
      background-color: #0779f7;
      border: 1px solid #0779f7;
      color: $white;
      text-transform: capitalize;
      display: flex;
      justify-content: center;
      padding: 5px;
      cursor: pointer;

      &:hover,
      &:focus {
        background: #4c9eea;
        border: 1px solid #4c9eea;
        color: $white;
      }
    }
  }
}

.container {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .card {
    cursor: pointer;
    background: $white;
    padding: 20px;
    width: 50%;
    box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    .iconWrapper {
      border-radius: 10px;
      background: #0779f7;
      width: 50%;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      .text {
        color: $textColor;
        margin-top: 10px;
        font-size: 12px;
      }
    }
  }
}
