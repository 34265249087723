.head {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  color: #950303;
}
/* .cardcontainer {
      margin-top: 20px;
      margin-bottom: 20px;
      cursor: pointer;
    }
    .cardText {
      font-weight: bold;
    }
    .cardText {
      color: #a12c2c;
    }
    .cardStatus {
      background-color: green;
      color: white;
      margin: 0px auto;
      width: 80%;
      border-radius: 2px;
    }
    .cardPending {
      background-color: rgb(123, 123, 55);
      border-radius: 2px;
      color: white;
      margin: 0px auto;
      width: 80%;
      border-radius: 5px;
    }
    
    .box {
      border-radius: 15px;
    padding: 5px 10px;
    box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
    margin: 20px 10px;
    cursor: pointer;
    } */

/* .innerContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    } */

.title {
  font-size: 25px;
  font-weight: 700;
  color: #950303;
  text-transform: uppercase;
}

/* .content {
      display: flex;
      text-overflow: ellipsis;
    } */

.header {
  align-items: center;
  font-size: 12px;
  color: #707174;
  text-transform: uppercase;
  margin: 0%;
  margin-bottom: 5px;
}

.rowWithBorder {
  border-bottom: 1px solid #ddd;
}

.text {
  color: #07153d;
  font-size: 14px;
  /* width: 150px; */
}

.textButton {
  display: flex;
  gap: 3rem;
  color: #07153d;
  font-size: 14px;
  /* width: 150px; */
}

.icon {
  border-radius: 50%;
  background-color: #fde0e0;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.boxcontent {
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  /* flex: 1; */
}
.buttonText {
  color: black;
  text-align: center;
  /* font-size: 15px; */
}

.emptyMessage {
  display: flex;
  justify-content: center;
}

table {
  width: 100%;
}

th,
td {
  padding: 12px;
  text-align: left;
}

/* Button styles */
.sendToOvaButton {
  color: white;
  background: #950303;
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  box-shadow: rgba(32, 32, 36, 0.25) 0px 6px 12px -2px;
}

.sendToOvaButton:hover {
  background-color: #63b32d;
}

.iconTextContainer {
  display: flex;
  align-items: center;
}

.quipOrStockText {
  margin-left: 10px;
}
