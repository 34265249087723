.circle {
  background-color: #fff;
  color: #999;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid gray;
  transition: 0.4s ease;
}

.circle.active {
  background-color: red;
  color: white;
}

.progressbar {
  position: relative;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
}

.progressbar::before {
  content: "";
  position: absolute;
  top: 50%;
  background-color: gray;
  left: 0;
  transform: translateY(-50%);
  border-radius: 5px;
  height: 4px;
  width: 100%;
  z-index: -1;
  transition: 0.4s ease;
}

.progress {
  position: absolute;
  top: 50%;
  background-color: blue;
  left: 0;
  transform: translateY(-50%);
  border-radius: 5px;
  height: 4px;
  width: 50%;
  z-index: -1;
  transition: 0.4s ease;
}
