@import "../variables/variables.scss";

.flexContainer1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0px;
  max-width: 250px;
  background-color: $darkRed;
  height: 100%;

  .logo {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    margin-bottom: 40px;
  }

  .icon {
    margin-right: 30px;
    margin-left: 10px;
  }

  .bottom {
    padding: 0.3rem 1rem;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    color: $white;
    font-weight: 700;

    .avatar {
      background-color: $white;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      margin-bottom: 3px;
    }
  }

  .link,
  .link:hover,
  .link:focus {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $white;
    font-size: 15px;
    font-weight: 600;
    position: relative;
  }
}

// small
.flexContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  max-width: 100px;
  background-color: $darkRed;
  height: 100%;

  .top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .bottomContainer {
    .bottom2 {
      padding: 0.3rem 1rem;
      margin-top: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
      color: $white;
      font-weight: 700;

      .avatar {
        background-color: $white;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin-bottom: 3px;
      }
    }
  }

  .logo {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    margin-bottom: 40px;
  }

  .icon {
    margin-right: 20px;
    margin-left: 20px;
  }

  .link,
  .link:hover,
  .link:focus {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $white;
    font-size: 17px;
    font-weight: 600;
  }
}

.menu {
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  &:hover {
    width: 100%;
    border-radius: 9px;
    padding: 10px;
    border: 1px solid $lightRed;
    background-color: $lightRed;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
}

.active {
  width: 100%;
  border-radius: 9px;
  padding: 10px;
  border: 1px solid $lightRed;
  background-color: $lightRed;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.menu:hover,
.active {
  border-radius: 0px 0px 20px 0px;
}

.hover {
  // position: absolute;
  // top: 20px;
  // right: 5px;
  font-size: 8px;
  text-align: center;
}
