/* Modal.module.css */

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-width: 60%;
  animation: animatezoom 0.6s;
  height: 30%;
  width: 28%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headerModal {
  text-align: center;
  margin-top: 20px;
  font-size: 1.3em;
  line-height: 30px;
}

.btn:hover {
  background-color: white;
  color: #950303;
  border: 1px solid #950303;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.closeButton {
  background-color: #ccc;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.closeButton:hover {
  background-color: #999;
}

.buttonContianer {
  display: flex;
  margin: 0px auto;
}
.btn:hover {
  background-color: white;
  color: #950303;
  border: 1px solid #950303;
}

.btn {
  background-color: #950303;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  width: 100px;
}
.btnContainer {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  gap: 10px;
}
.btn:hover {
  background-color: white;
  color: #950303;
  border: 1px solid #950303;
}

.item {
  color: #950303;
}
