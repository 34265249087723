.card {
  /* background: #292929; */
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
}

.label {
  font-weight: 700;
  font-size: 20px;
  margin: 10px;
  /* line-height: 29px; */

  color: #292929;
}
