@import '../../../components/variables/variables.scss';

.header {
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
  color: $lightRed;
  font-weight: 900;
  margin-bottom: 20px;
}

.btn,
.btn:hover,
.btn:focus {
  width: 100%;
  background: $darkRed;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  border-radius: 10px;
  border: inherit;
  margin: 20px auto;
  padding: 10px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 20px;
  color: $white;
}
