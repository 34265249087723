@import "../../../components/variables/variables.scss";

.header {
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
  color: $lightRed;
  font-weight: 900;
}

.formInput {
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  border-radius: 10px;
  /* height: 45px; */
  /* font-size: 13px; */
}

.requestHeader,
.requestHeader1 {
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: $lightRed;
  text-transform: uppercase;
  margin-top: 20px;
}
.requestHeader {
  text-decoration: underline;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 1rem;
}

.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 15rem;
}

@media (min-width: 968px) {
  .form {
    padding: 0 5rem;
  }
}

.heading {
  color: #27156f;
  font-weight: 400;
}

.inputText {
  font-size: 12px;
}

.button {
  width: 100%;
  border-radius: 5px;
  font-weight: 600;
  background-color: $darkRed;
  border: 1px solid $darkRed;
  color: $white;

  &:hover,
  &:focus {
    background: $lightRed;
    border: 1px solid $lightRed;
    color: $white;
  }
}

.forgot {
  text-decoration: none;
  color: #8569f5;
  font-weight: 700;
}

.about {
  color: #8569f5;
  font-weight: 700;
}

.power {
  color: #27156f;
  font-size: 1rem;
  font-weight: 600;
}

.input {
  color: #111111;
  font-size: 14px;
  padding: 10px;
  font-weight: 500;
  background-color: #f4f4f4;
  border: none;
  border-radius: 30px;
}

.group {
  position: relative;
}

.icon {
  position: absolute;
  top: 8px;
  right: 20px;
  color: #d4d4d4;
  font-size: 1.2rem;
}

.optional {
  color: gray;
  font-size: 14px;
  font-style: italic;
}
