.mainContainer {
  padding: 30px 10px;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  border-radius: 20px;
  margin-bottom: 20px;
}

.container {
  display: flex;
  justify-content: center;
  min-width: 100%;
  align-items: center;
}

.circles {
  border-radius: 50%;
  min-height: 120px;
  min-width: 120px;
  max-height: 120px;
  max-width: 120px;
  border: 7px solid #950303;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.connect {
  background-color: #950303;
  width: 50px;
  height: 20px;
  position: relative;
  transform: skewX(-20deg, -20deg);
}
.connect::before,
.connect::after {
  content: '';
  background-color: #950303;
  height: 20px;
  width: 5px;
  position: absolute;
}

.connect::before {
  left: -2px;
}

.connect::after {
  right: -2px;
}
