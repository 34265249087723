/* Modal.module.css */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 30%;
    max-height: 80%;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

 
  