@import "../../../components/variables/variables.scss";

.header {
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
  color: $lightRed;
  font-weight: 900;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 1rem auto;

  .card {
    background: #fefefe;
    box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
    border-radius: 10px;
    text-decoration: none;
    font-size: 22px;
    font-weight: 900;
    color: #323c47;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    &:hover {
      background: #464646;
      color: $white;
    }
  }
}

.btn,
.btn:hover,
.btn:focus {
  width: 100%;
  background: $darkRed;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  border-radius: 10px;
  border: inherit;
  margin: 20px auto;
  padding: 10px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 20px;
  color: $white;
}

.other {
  color: $lightRed;
  text-align: right;
  cursor: pointer;
  font-size: medium;
}
