.container {
  border-radius: 15px;
  padding: 5px 10px;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  margin: 20px 10px;
  cursor: pointer;
}

.title{
  font-size: 25px;
  font-weight: 700;
  color: #950303;
  text-transform: uppercase;
}


.content {
  display: flex;
  text-overflow: ellipsis;
}

.icon {
  border-radius: 50%;
  background-color: #fde0e0;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.header {
  font-size: 12px;
  color: #707174;
  text-transform: uppercase;
  margin: 0%;
  margin-bottom: 5px;
}

.text {
  color: #07153d;
  font-size: 14px;
  width: 150px;
}

.innerContainer {
  display: flex;
  justify-content: space-between;
}


  .image {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
  }

  .emptyMessage{
    display: flex;
    justify-content: center;
  }
