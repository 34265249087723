.hasSearch {
  position: relative;
}

.SearchBox {
  padding: 0.5rem 2rem;
  outline: none;
  border: 2px solid #f6f6f6;
  color: #c4c4c4;
  font-weight: 400;
  font-size: 14px;
  width: 250px;
  background: #ffffff;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  border-radius: 20px;
}

.SearchBox:focus {
  font-weight: normal;
  border: 1.8px solid #f6f6f6;
  background: #ffffff;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
}

.formControlSearchIcon {
  position: absolute;
  z-index: 2;
  display: block;
  width: 1rem;
  height: 2.3rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  left: 200px;
  color: #c4c4c4;
}
