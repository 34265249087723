@import '../variables/variables.scss';

.navbarCont {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;

  .navbarContInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    max-width: 97%;
    padding: 10px 0;

    .dashboardName {
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
      color: #950303;
    }

    .navContentLeftIcons {
      margin-right: 40px;
      font-size: 20px;
      color: #686b6f;
      cursor: pointer;
      position: relative;
      background-color: $darkRed;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      // padding: 10px;

      .count {
        position: absolute;
        top: 5px;
        right: 7px;
        background-color: $white;
        color: $lightRed;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}

/* Responsiveness */
@media (max-width: 425px) {
  .dashboardName {
    font-size: 14px;
  }
}

@media (max-width: 375px) {
  .navContentLeftIcons {
    margin-right: 20px;
  }
}
