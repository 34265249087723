.container {
  border-radius: 15px;
  padding: 15px;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  margin: 20px 10px;
  width: 99%;
  margin-left: 20px;
  position: relative;
}

.innerContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0px 20px;
}

.badge {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b70303;
  color: #fff;
  font-weight: 600;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  top: -25px;
  left: -30px;
}

.badge2 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b70303;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  border-radius: 5px;
  padding: 3px 25px;
  bottom: 5px;
  left: -7px;
}
.content {
  display: flex;
  text-overflow: ellipsis;
}

.address {
  width: 200px;
}

.icon {
  border-radius: 50%;
  background-color: #fde0e0;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.header {
  font-size: 12px;
  color: #707174;
  text-transform: uppercase;
  margin: 0%;
  margin-bottom: 5px;
}

.text {
  color: #07153d;
  font-size: 14px;
}

.time {
  color: #85878e;
  font-size: 12px;
}

.button {
  border: 1px solid #b70303;
  color: #b70303;
  font-size: 14px;
  text-transform: uppercase;
  background-color: #fff;
  padding: 4px 15px;
  font-weight: 700;
}
