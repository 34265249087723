.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.container {
  background-color: #fff;
  padding: 8px;
  display: flex;
  gap: 10px;
  min-width: 260px;
}

.circleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.line {
  background-color: #707174;
  width: 1px;
  height: 40px;
}

.lineActive {
  background-color: #3b7812;
  width: 2px;
  height: 40px;
}

.label {
  color: #383737;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.description {
  color: #85878e;
  font-size: 16px;
  font-weight: 600;
}

.circle {
  width: 14px;
  height: 14px;
  line-height: 25px;
  background-color: #707174;
  color: white;
  border-radius: 50%;
  border: 2px solid transparent;
}

.circleActive {
  width: 14px;
  height: 14px;
  line-height: 25px;
  background-color: #3b7812;
  color: white;
  border-radius: 50%;
  border: 2px solid transparent;
}
