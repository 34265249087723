@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

$font: 'Lato', sans-serif;

// colors
$textColor: #707174;
$darktext: #2c2b2b;
$orange: #fba243;
$lightPink: #fde0e0;
$grayButton: #a9b9c6;
$darkRed: #950303;
$lightRed: #b70303;
$lightGreen: #f0fde0;
$green: #78a55a;
$blue: #0779f7;
$darkGreen: #066304;
$black: #333333;
$white: white;
