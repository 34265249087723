@import "../../../components/variables/variables.scss";

.header {
  display: flex;
  // justify-content: center;
  gap: 10%;
  align-items: center;
  background: $lightRed;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  border-radius: 10px;
  color: $white;
  padding: 7px;
  font-weight: 800;
  font-size: 20px;
  text-decoration: underline;
  margin-bottom: 20px;
}

.btn {
  background: $darkRed;
  width: 100%;
  color: $white;
  border: none;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  border-radius: 10px;
  padding: 7px;
  font-size: 20px;
  margin: 20px auto;
}
