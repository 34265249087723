@import '../../../components/variables/variables.scss';

.header {
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
  color: $lightRed;
  font-weight: 900;
}

.formInput {
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  border-radius: 10px;
  /* height: 45px; */
  /* font-size: 13px; */
}

.requestHeader,
.requestHeader1 {
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: $lightRed;
  text-transform: uppercase;
  margin-top: 20px;
}
.requestHeader {
  text-decoration: underline;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 1rem;
}
