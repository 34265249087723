@import "../../components/variables/variables.scss";

.map {
  background: $white;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  border-radius: 20px;
  margin: 10px 20px;
}

.orderId {
  background-color: #fde0e0;
  padding: 10px;
  color: $darkRed;
  margin-left: 10px;
  // margin-bottom: 7px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: max-content;

  .text {
    color: #35373b;
    margin-left: 5px;
  }
}
