.purchaseOrderText {
  font-size: 40px;
  font-weight: 700;
  color: #950303;
  cursor: pointer;
}

.uploadCon {
  display: flex;
  justify-content: space-evenly;
  padding-left: 0;
  padding-right: 10rem;
}

.boxShadow {
  display: flex;
  height: 700px;
  width: 700px;
  border-radius: 2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px;
}

.imagecontainer {
  width: 50%;
  height: auto;
  object-fit: contain;
  cursor: pointer;
  // background-color: #B70303;
}

.imgBtn{
  background-color: #B70303;
  color: #f2f2f2;
  border: none;
  border-radius: 8px;
  margin-top: 1.5rem;
  text-align: center;
  font-weight: 700;
}

.imagedetails {
  height: 700px;
  width: 700px;
  border-radius: 2rem;
}

.productContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px;
  width: 100%;
  height: 25rem;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: center;
}

h1 {
  font-size: 40px;
  font-family: "Lato", sans-serif;
  font-weight: 800;
  color: #b70303;
}

.select {
  margin-top: 0;
  padding: 10px;
}

select {
  width: 30rem;
  height: 2.5rem;
  background: whitesmoke;
  border-radius: 8px;
  border: none;
  color: #545f7d;
  padding: 5px;
  outline: none;
  font-size: 20px;
  font-weight: 400;
}

.priceQtty {
  display: flex;
  justify-content: space-around;
  gap: 65px;
}

input {
  width: 10rem;
  height: 2.5rem;

  background: whitesmoke;
  border: none;
  border-radius: 8px;
  color: #545f7d;
  outline: none;
  padding: 5px;
  font-size: 20px;
}
.quantityControl {
  display: flex;
  gap: 30px;
  background: whitesmoke;
  width: 13rem;
  height: 2.5rem;
  padding: 5px;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.p {
  font-size: 20px;
  font-weight: 400;
  color: #545f7d;
  margin-left: 5px;
}

.plusMinus {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  gap: 4px;
}

.addButton {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #b70303;
  color: white;
  border: none;
  width: 5rem;
  height: 2rem;
  font-size: 15px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: rgba(16, 16, 19, 0.25) 0px 6px 12px -2px;
}

.scrollableContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  overflow-y: auto;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px;
  width: 100%;
  max-height: 300px;
  padding: 5px;
  font-family: "Lato", sans-serif;
}

.button {
  background: #b70303;
  color: white;
  border: none;
  font-family: "Lato", sans-serif;
  border-radius: 6px;
  font-weight: bold;
}

table {
  width: 100%;
  margin-top: 5px;
}
th,
td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.btnCon {
  background-color: #b70303;
  width: 7rem;
  height: 2rem;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 25px;
  box-shadow: rgba(32, 32, 36, 0.25) 0px 6px 12px -2px;
}

.buttonText {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  text-align: center;
  // margin-top: 0.25rem;
  color: white;
  font-size: 20px;
}

.sumit{
  display: flex;
  flex-direction: column;
}
.modalImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}



@media (min-width: 1025px) and (max-width: 1280px){ 
  .purchaseOrderText {
    font-size: 30px;
    font-weight: 700;
    color: #950303;
    cursor: pointer;
    top: 0;
    padding: 5px;
    padding-bottom: 10px;
  }

  .uploadCon{
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 6rem;
  }

  .boxShadow{
    display: flex;
    height: 500px;
    width: 500px;
    border-radius: 2rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px;
   }


.imagedetails {
  height: 500px;
  width: 500px;
  border-radius: 2rem;
}

.updateCon{
  display: flex;
  flex-direction: column;
  
}

.productContainer{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px;
  width: 100%;
  // height: 17rem;
  padding: 15px;
}

.header{
  display: flex;
  justify-content: center;


  h1{
    font-size: 30px;
    font-family: "Lato", sans-serif;
    font-weight:800;
    color: #B70303;
  }
}

.select{
  margin-top: 0;
  padding: 20px;
 
  
  select{
    width: 30rem;
    height: 2.5rem;
    background: whitesmoke;
    border-radius: 8px;
    border: none;
    color: #545f7d;
    padding: 5px;
    outline: none;
    font-size: 20px;
    font-weight: 400;
  }
}

// .btnCon{
  
//   background-color: #b70303;
//   display: block;
//   position: absolute;
//   right: 2.2rem;
//   bottom: 5rem;
//   width: 10%;
//   height: 5%;
//   align-items: center;
//   cursor: pointer;
//   border-radius: 5px;
//   margin-top: 25px;
//   box-shadow: rgba(32, 32, 36, 0.25) 0px 6px 12px -2px;
 

//   .buttonText {
//     font-family: "Lato", sans-serif;
//     font-weight: bold;
//     text-align: center;
//     margin-top:  0.5rem;
//     color: white;
//     font-size: 18px;
//   }
// }


.scrollableContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px; 
  overflow-y: auto;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px;
  width: 100%;
  max-height: 220px;
  padding: 5px;
  font-family: "Lato", sans-serif;


  table {
    width: 100%;
    margin-top: 5px; 
   


    th, td {
      padding: 8px;
      text-align: left;
    }
    
    th {
      background-color: #f2f2f2;
    }
  }

  .button{
    background: #B70303;
    color: white;
    border: none;
    font-family: "Lato", sans-serif;
    border-radius: 6px;
    font-weight: bold;
  }
  
}
} 


// /* Media Query for Large screens */
// @media (min-width: 1281px) {
 
//   .purchaseOrderText {
//     font-size: 40px;
//     font-weight: 700;
//     color: #950303;
//     cursor: pointer;
//   }
//   .uploadCon {
//     display: flex;
//     justify-content: space-evenly;
//     padding-left: 0;
//     padding-right: 10rem;
//   }
//   .boxShadow {
//     display: flex;
//     height: 700px;
//     width: 700px;
//     border-radius: 2rem;
//     box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px;
//   }
  
//   .imagecontainer {
//     width: 50%;
//     height: auto;
//     object-fit: contain;
//     cursor: pointer;
//   }
  
//   .imagedetails {
//     height: 700px;
//     width: 700px;
//     border-radius: 2rem;
//   }
  
//   .updateCon {
//     display: flex;
//     flex-direction: column;
//   }
  
//   .productContainer {
//     display: flex;
//     justify-content: space-between;
//     flex-direction: column;
//     align-items: center;
//     background-color: white;
//     box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px;
//     width: 100%;
//     height: 17rem;
//     padding: 20px;
//   }
//   .header {
//     display: flex;
//     justify-content: center;
  
//     h1 {
//       font-size: 40px;
//       font-family: "Lato", sans-serif;
//       font-weight: 800;
//       color: #b70303;
//     }
//   }
  
//   .select {
//     margin-top: 0;
//     padding: 20px;
  
//     select {
//       width: 30rem;
//       height: 2.5rem;
//       background: whitesmoke;
//       border-radius: 8px;
//       border: none;
//       color: #545f7d;
//       padding: 5px;
//       outline: none;
//       font-size: 20px;
//       font-weight: 400;
//     }
//   }
  
//   .priceQtty {
//     display: flex;
//     justify-content: space-around;
//     gap: 65px;
  
//     .priceInput {
//       // padding-left: 16px;
  
//       input {
//         width: 10rem;
//         height: 2.5rem;
//         // font-style: italic;
//         background: whitesmoke;
//         border: none;
//         border-radius: 8px;
//         color: #545f7d;
//         outline: none;
//         padding: 5px;
//         font-size: 20px;
//       }
//     }
  
//     .quantityControl {
//       display: flex;
//       gap: 30px;
//       background: whitesmoke;
//       width: 13rem;
//       height: 2.5rem;
//       padding: 5px;
//       border-radius: 8px;
//       margin-bottom: 1rem;
  
//       .p {
//         font-size: 20px;
//         font-weight: 400;
//         color: #545f7d;
//         margin-left: 5px;
//       }
  
//       .plusMinus {
//         display: flex;
//         justify-content: space-evenly;
//         align-items: center;
//         text-align: center;
//         gap: 4px;
//       }
//     }
//   }
  
//   .addButton {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     text-align: center;
//     background: #b70303;
//     color: white;
//     border: none;
//     width: 5rem;
//     height: 2rem;
//     font-size: 15px;
//     font-weight: bold;
//     font-family: "Lato", sans-serif;
//     cursor: pointer;
//     border-radius: 6px;
//     box-shadow: rgba(16, 16, 19, 0.25) 0px 6px 12px -2px;
//   }
  
//   .btnCon {
//     background-color: #b70303;
//     display: block;
//     position: absolute;
//     right: 19rem;
//     bottom: 10rem;
//     width: 10%;
//     height: 5%;
//     align-items: center;
//     cursor: pointer;
//     border-radius: 5px;
//     margin-top: 25px;
//     box-shadow: rgba(32, 32, 36, 0.25) 0px 6px 12px -2px;
  
//     .buttonText {
//       font-family: "Lato", sans-serif;
//       font-weight: bold;
//       text-align: center;
//       margin-top: 0.25rem;
//       color: white;
//       font-size: 25px;
//     }
//   }
  
//   /* ScrollableTable.css */
  
//   .scrollableContainer {
//     display: flex;
//     justify-content: center;
//     margin-top: 30px;
//     overflow-y: auto;
//     background-color: white;
//     box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px;
//     width: 100%;
//     max-height: 300px;
//     padding: 5px;
//     font-family: "Lato", sans-serif;
  
//     table {
//       width: 100%;
//       margin-top: 5px;
    
  
//       th,
//       td {
//         padding: 8px;
//         text-align: left;
//       }
  
//       th {
//         background-color: #f2f2f2;
//       }
//     }
  
//     .button {
//       background: #b70303;
//       color: white;
//       border: none;
//       font-family: "Lato", sans-serif;
//       border-radius: 6px;
//       font-weight: bold;
//     }
//   }
  
//   .modalImage {
//     max-width: 100%;
//     max-height: 100%;
//     object-fit: contain;
//   }
// }

// @media (min-width: 2560px){
//   .purchaseOrderText {
//     font-size: 70px;
//     font-weight: 700;
//     color: #950303;
//     cursor: pointer;
//   }
//   .uploadCon{
//     display: flex;
//     justify-content: space-around;
//     gap: 45rem;
//     margin-bottom: 6rem;
//   }
//   .boxShadow {
//     display: flex;
//     height: 100px;
//     width: 100px;
//     border-radius: 2rem;
//     box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px;
//   }
  
//   .imagecontainer {
//     width: 50%;
//     height: auto;
//     object-fit: contain;
//     cursor: pointer;
//   }
  
//   .imagedetails {
//     height: 1000px;
//     width: 1000px;
//   }

//   .productContainer {
//     display: flex;
//     justify-content: space-between;
//     flex-direction: column;
//     align-items: center;
//     background-color: white;
//     box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px;
//     width: 130%;
//     height: 25rem;
//   }
//   .header {
//     display: flex;
//     justify-content: center;
  
//     h1 {
//       font-size: 60px;
//       font-family: "Lato", sans-serif;
//       font-weight: 800;
//       color: #b70303;
//     }
//   }

//   .select {
//     margin-right: 18rem;
  
//     select {
//       width: 200%;
//       height: 4rem;
//       background: whitesmoke;
//       border-radius: 8px;
//       border: none;
//       color: #545f7d;
//       padding: 5px;
//       outline: none;
//       font-size: 20px;
//       font-weight: 400;
//     }
//   }


//   .priceQtty {
//     display: flex;
//     justify-content: space-around;
//     gap: 220px;
  
//     .priceInput {
  
//       input {
//         width: 10rem;
//         height: 4rem;
//         background: whitesmoke;
//         border: none;
//         border-radius: 8px;
//         color: #545f7d;
//         outline: none;
//         padding: 5px;
//         font-size: 20px;
//       }
//     }
  
//     .quantityControl {
//       display: flex;
//       background: whitesmoke;
//       width: 13rem;
//       height: 4rem;
//       padding: 5px;
//       border-radius: 8px;
//       margin-bottom: 1rem;
  
//       .p {
//         font-size: 20px;
//         font-weight: 400;
//         color: #545f7d;
//         margin-left: 5px;
//       }
//     }
//   }

//   .addButton {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     text-align: center;
//     background: #b70303;
//     color: white;
//     border: none;
//     width: 8rem;
//     height: 3rem;
//     font-size: 25px;
//     font-weight: bold;
//     font-family: "Lato", sans-serif;
//     cursor: pointer;
//     border-radius: 6px;
//     box-shadow: rgba(16, 16, 19, 0.25) 0px 6px 12px -2px;
//   }


//   .btnCon {
//     background-color: #b70303;
//     display: block;
//     position: absolute;
//     right: 11rem;
//     width: 8%;
//     height: 4%;
//     align-items: center;
//     cursor: pointer;
//     border-radius: 5px;
//     margin-bottom: 10rem;
//     box-shadow: rgba(32, 32, 36, 0.25) 0px 6px 12px -2px;
  
//     .buttonText {
//       font-family: "Lato", sans-serif;
//       font-weight: bold;
//       text-align: center;
//       margin-top: 1rem;
//       color: white;
//       font-size: 25px;
//     }
//   }


//   .scrollableContainer {
//     display: flex;
//     justify-content: center;
//     margin-top: 30px;
//     overflow-y: auto;
//     background-color: white;
//     box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px;
//     width: 130%;
//     max-height: 500px;
//     padding: 5px;
//     font-family: "Lato", sans-serif;
  
//     table {
//       width: 100%;
//       margin-top: 5px;
  
//       th,
//       td {
//         padding: 8px;
//         text-align: left;
//       }
  
//       th {
//         background-color: #f2f2f2;
//       }
//     }
  
//     .button {
//       background: #b70303;
//       color: white;
//       border: none;
//       font-family: "Lato", sans-serif;
//       border-radius: 6px;
//       font-weight: bold;
//     }
//   }
// }




/* Small Laptops (e.g., MacBook Air 13-inch) */
@media (max-width: 1440px) {
  .purchaseOrderText {
    font-size: 30px;
  }

  .boxShadow {
    height: 500px;
    width: 500px;
  }

  .imagedetails {
    height: 500px;
    width: 500px;
  }

  .productContainer {
    height: 17rem;
  }

  .header h1 {
    font-size: 30px;
  }

  .select select {
    width: 30rem;
  }

  .quantityControl input {
    width: 10rem;
  }

  // .btnCon {
  //   right: 11.5rem;
  //   top: 40rem
  // }

  // .buttonText{
  //   font-size: 20px;
  //   margin-top: 0.5rem;
  // }

  .scrollableContainer {
    max-height: 220px;
  }
}

/* Medium Laptops (e.g., Dell XPS 15) */
@media (min-width: 1441px) and (max-width: 1920px) {
  .purchaseOrderText {
    font-size: 40px;
  }

  .boxShadow {
    height: 600px;
    width: 600px;
  }

  .imagedetails {
    height: 600px;
    width: 600px;
  }

  .productContainer {
    height: 17rem;
  }

  .imageContainer, .productContainer {
    width: 100%;
  }

  .header h1 {
    font-size: 40px;
  }

  .select select {
    width: 30rem;
  }

  // .btnCon {
  //   right: 11.5rem;
  // }

  .scrollableContainer {
    max-height: 300px;
  }
}

/* Large Laptops (e.g., HP Omen 17) */
@media (min-width: 1921px) and (max-width: 2560px) {
  .purchaseOrderText {
    font-size: 50px;
  }

  .uploadCon{
    display: flex;
    justify-content: space-evenly;
  }

  .boxShadow {
    height: 800px;
    width: 800px;
  }

  .imagedetails {
    height: 800px;
    width: 800px;
  }

  .productContainer {
    height: 20rem;
  }

  .imageContainer, .productContainer {
    width: 100%;
  }

  .header h1 {
    font-size: 50px;
  }

  .select select {
    width: 35rem;
  }

  // .btnCon {
  //   right: 12rem;
  // }

  .scrollableContainer {
    max-height: 350px;
  }
}

/* Larger Laptops (e.g., MSI GT76 Titan) */
@media (min-width: 2561px) {
  .purchaseOrderText {
    font-size: 70px;
  }

  .boxShadow {
    height: 1300px;
    width: 1300px;
  }

  .imagedetails {
    height: 1300px;
    width: 1300px;
  }

  .productContainer {
    height: 25rem;
  }

  .imageContainer, .productContainer {
    width: 100%;
  }

  .header h1 {
    font-size: 80px;
  }

  .select select {
    width: 60rem;
    
  }

  // .btnCon {
  //   right: 11rem;
  // }

  .scrollableContainer {
    max-height: 500px;
  }
}
