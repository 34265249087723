@import "../variables/variables.scss";

.title {
  font-size: 20px;
  color: $lightRed;
  text-decoration: underline;
  text-align: center;
  line-height: 29px;
  font-weight: 700;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: $white;
  box-shadow: 1px 2px 10px rgba(112, 144, 176, 0.1);
  border-radius: 10px;
  margin-bottom: 5px;

  .icon {
    background: #f8f8f8;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .text {
    color: #85878e;
    font-size: 17px;
    font-weight: 700;
    width: 45vw;

    .ordertype {
      color: #000;
    }
  }

  .time {
    display: flex;
    width: 20vw;
    .timeText {
      color: #000;
      font-weight: 700;
    }
  }

  .btn {
    border: 1px solid $lightRed;
    border-radius: 20px;
    padding: 5px 20px;
    color: $lightRed;
    font-weight: 700;
    cursor: pointer;
  }
}

.notification {
  color: $darkRed;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}
