.map {
  position: relative;
  margin-left: 10px;
}

.lite {
  position: absolute;
  left: -15px;
  top: 90px;
  font-size: 24px;
  font-weight: 700;
  z-index: 1000;
  background-color: #950303;
  color: #fff;
  padding: 5px 30px;
  border-radius: 10px 10px 30px 10px;
}

.recenter {
  position: absolute;
  right: 10px;
  bottom: 110px;
  font-size: 24px;
  font-weight: 700;
  z-index: 1000;
  background-color: #fff;
  color: #666;
  padding: 8px;
  border: 0;
  border-radius: 5px;
}

.status {
  position: absolute;
  left: 35px;
  top: 170px;
  // width: 300px;
  font-size: 24px;
  font-weight: 700;
  z-index: 1000;
  background: rgba(226, 226, 226, 0.55);
  // color: #fff;
  padding: 5px 30px;
  // border-radius: 10px 10px 30px 10px;
}

.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.googleMap {
  width: 100%;
  height: 70vh;
}

.pin {
  display: flex;
  align-items: center;
  width: 180px;
  color: var(--main-blue);
}

.pin-icon {
  font-size: 4rem;
}

.pin-text {
  font-size: 1.3em;
}

.orderId {
  background-color: #fde0e0;
  padding: 10px;
  color: #950303;
  margin-bottom: 7px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: max-content;
}

.text {
  color: #35373b;
  margin-left: 5px;
}
@media screen and (min-width: 799px) {
  .google-map {
    height: 80vh;
  }

  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 15vw;
  }

  .pin-icon {
    font-size: 10vw;
  }
}
