@import "../../../components/variables/variables.scss";

.container {
  width: 97%;

  .header {
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
    color: $lightRed;
    font-weight: 900;
  }

  .formInput {
    box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
    border-radius: 10px;
    /* height: 45px; */
    /* font-size: 13px; */
  }

  .requestHeader,
  .requestHeader1 {
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: $lightRed;
    text-transform: uppercase;
    margin-top: 20px;
  }
  .requestHeader {
    text-decoration: underline;
  }
  .request,
  .request:hover,
  .request:focus {
    display: flex;
    margin: 20px auto;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $darkRed;
    color: $white;
    gap: 20px;
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    box-shadow: 1px 2px 10px 3px #ffd0d0;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
  }
  .request2,
  .request2:hover,
  .request2:focus {
    display: flex;
    margin: 20px auto;
    padding: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $darkRed;
    color: $white;
    gap: 10px;
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    box-shadow: 1px 2px 10px 3px #ffd0d0;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
  }
}

.datePicker {
  height: 4vh;
  border: none;
  border-radius: 10px;
  color: black;
  padding: 10px;
  background-color: #f5f5f6;
  text-align: center;
}

.schedule {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.picker {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.btn {
  width: 50%;
  color: white;
  margin-top: 20px;
  font-weight: 600;
  font-size: 18px;
}

.inner {
  display: flex;
  flex-direction: column;
  // align-items: center;
}