@import "../variables/variables.scss";
.modal {
  .close {
    z-index: 13000000;
    position: absolute;
    top: -13px;
    left: -13px;
    background-color: #7b7575;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    color: $white;
  }

  .actionBtn {
    font-size: 13px;
    min-width: 190px;
    border: none;
    border-radius: 5px;
    width: 22px;
    padding: 9px;
    margin: 0 0.5rem;
    font-weight: 700;
    text-align: center;
  }

  .confirm,
  .confirm:hover,
  .confirm:focus {
    background: #484545;
    color: $white;
  }

  .reject,
  .reject:hover,
  .reject:focus {
    background: $darkRed;
    color: $white;
  }
}
