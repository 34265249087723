.head {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  color: #950303;
}
.cardcontainer {
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.cardText {
  font-weight: bold;
}
.cardText {
  color: #a12c2c;
}
.cardStatus {
  background-color: green;
  color: white;
  margin: 0px auto;
  width: 80%;
  border-radius: 2px;
}
.cardPending {
  background-color: rgb(123, 123, 55);
  border-radius: 2px;
  color: white;
  margin: 0px auto;
  width: 80%;
  border-radius: 5px;
}

.box {
  border-radius: 15px;
  padding: 5px 10px;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  margin: 20px 10px;
  cursor: pointer;
}

.innerContainer {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 25px;
  font-weight: 700;
  color: #950303;
  text-transform: uppercase;
}

.content {
  display: flex;
  text-overflow: ellipsis;
}

.header {
  font-size: 12px;
  color: #707174;
  text-transform: uppercase;
  margin: 0%;
  margin-bottom: 5px;
}

.text {
  color: #07153d;
  font-size: 14px;
  width: 150px;
}

.rowWithBorder {
  border-bottom: 1px solid #ddd;
}

.icon {
  border-radius: 50%;
  background-color: #fde0e0;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.boxcontent {
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  /* flex: 1; */
}
.processbutton {
  border: "1px solid #ddd";
}
.buttonText {
  color: black;
  /* text-align: center; */
  /* font-size: 15px; */
}

.emptyMessage {
  display: flex;
  justify-content: center;
}

.sendToOvaButton {
  color: white;
  background: #950303;
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  box-shadow: rgba(32, 32, 36, 0.25) 0px 6px 12px -2px;
}

.viewButton {
  color: white;
  background: green;
  padding: 5px;
  width: 100px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: rgba(32, 32, 36, 0.25) 0px 6px 12px -2px;
}

/* .sendToOvaButton:hover {
  background-color: #63b32d;
} */

.sendToFinanceButton {
  color: white;
  background: #63b32d;
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  box-shadow: rgba(32, 32, 36, 0.25) 0px 6px 12px -2px;
}

.iconTextContainer {
  display: flex;
  align-items: center;
}

/* Style for the text */
.quipOrStockText {
  margin-left: 5px; /* Add margin between icon and text */
}

.finOva {
  display: flex;
  /* justify-content: space-evenly; */
  gap: 20px;
  margin: 0;
  padding: 0;
}
