.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.inputContainer {
  margin: 20px;
}
.input {
  width: 200px;
  height: 45px;
  padding: 5px;
  border: 2px #950303 solid;
  color: black;
  font-size: 18px;
  outline: none;
  border-radius: 10px;
  transition: all 0.3s ease;
}
.btnContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.btn {
  background-color: #950303;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  width: 100px;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-width: 60%;
  animation: animatezoom 0.6s;
  height: 40%;
  width: 28%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headerModal {
  text-align: center;
  margin-top: 20px;
  font-size: 1.3em;
}

.btn:hover {
  background-color: white;
  color: #950303;
  border: 1px solid #950303;
}
