.darkBG {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.modal {
  width: 50vw;
  max-height: 50vh;
  overflow-y: auto;
  background: white;
  color: white;
  z-index: 3;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.modalHeader {
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.heading {
  padding: 15px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.table {
  border-collapse: collapse;
  border: 1px solid #ddd;
  color: #6f6f6f;
  align-items: center;
  margin: 0 auto;
}

.headeritem,
.card,
.inputField {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.cardText {
  border: 0.5px solid #e1e1fb;
  background: #f7f7fc;
  padding: 5px 20px 5px 20px;
  margin: 10px;
  /* border-radius: 5px;  */
  display: inline-block;
  cursor: pointer;
}

.sendToOvaButton:hover {
  background-color: #f0f0f0;
}

.cardDot {
  display: flex;
  justify-content: space-between;
  border: 0.5px solid #ddd;
  padding: 20px;
  align-items: center;
}

.proceedContainer {
  min-width: 100px;
  min-height: 40px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background-color: darkred;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}


.loadingCell {
  display: flex;
  align-items: center;
  justify-content: center;
}
