@import "../../../../components/variables/variables.scss";

.header {
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
  color: $lightRed;
  font-weight: 900;
}

.cards {
  display: flex;
  justify-content: space-around;
  margin: 1rem auto;
}
