@import "../../variables/variables.scss";

.container {
  border-radius: 15px;
  padding: 15px;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  margin: 20px 10px;
  width: 99%;
  cursor: pointer;

  .time {
    color: #85878e;
    font-size: 12px;
  }

  .innerContainer {
    display: flex;
    justify-content: space-between;
  }

  .address {
    width: 200px;
  }

  .content {
    display: flex;
    text-overflow: ellipsis;

    .icon {
      border-radius: 50%;
      background-color: #fde0e0;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }

    .header {
      font-size: 12px;
      color: #707174;
      text-transform: uppercase;
      margin: 0%;
      margin-bottom: 5px;
    }

    .text {
      color: #07153d;
      font-size: 14px;
      width: 150px;
    }
    .pushbutton{
      color: white;
      text-align: center;
      font-size: 18px;
      width: 120px;
      background-color:#b70303;
      padding:2px;
      border-radius: 4px;
    }


    .button {
      border: 1px solid #b70303;
      color: #b70303;
      font-size: 14px;
      text-transform: uppercase;
      background-color: #fff;
      padding: 4px 15px;
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    overflow-x: scroll;
    padding: 10px 20px;
  }
}
