.title {
  font-size: 25px;
  font-weight: 700;
  color: #950303;
  text-transform: uppercase;
}

.bid {
  font-size: 15px;
  font-weight: bold;
  color: #707174;
  text-transform: uppercase;
  padding: 5px;
}

.card {
  display: flex;
  justify-content: space-evenly;
  margin: 10px;
}

.background {
  background: #fff;
  width: 40vw;
  height: 20vh;
  box-shadow: rgba(32, 32, 36, 0.25) 0px 6px 12px -2px;
  border-radius: 5px;
}

.detail {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 0.5rem;
  color: #707174;
}

.icon {
  color: #b70303;
  font-size: 20px;
}

.btn {
  display: flex;
  justify-content: flex-end;
  margin: 0 1rem 3rem 0;
}

.button {
  background-color: #b70303;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: rgba(32, 32, 36, 0.25) 0px 6px 12px -2px;
}
