@import "../../components/variables/variables.scss";

.container {
  display: flex;
  gap: 20px;
  margin-right: 10px;
}

.TableHeadRow {
  color: $textColor;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 700;
}

.tableBodyRow {
  color: #323c47;
  font-size: 18px;
  box-shadow: 1px 2px 10px rgba(112, 144, 176, 0.25);
  border-radius: 5px;

  .priority {
    background: $grayButton;
    mix-blend-mode: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
    border-radius: 10px;
    padding: 0px 10px;
  }

  .status {
    color: #3bc34e;
    font-weight: 600;
    font-size: 16px;
  }

  .close {
    color: #dd3d43;
  }

  .pending {
    color: #ebaf16;
  }
}

@media screen and (max-width: 600px) {
  .container {
    overflow-x: scroll;
    padding: 10px 20px;
  }
}
